import styled from "styled-components";
import { Heading, SectionHeading } from "src/components/Text";
import Button from "src/components/Common/Button";

interface Props {
  notificationBar: boolean;
}

export const Container = styled.main<Props>`
  overflow-x: hidden;
  padding-top: ${({ notificationBar }) =>
    notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    overflow-x: unset;
    padding-top: ${({ notificationBar }) =>
    notificationBar ? "10.7rem" : "6.9rem"};
  }
`;

export const NotFound = styled.div`
  background-color: ${({ theme }) => theme.colors.bg_pink};
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ notificationBar }) =>
    notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    padding-top: ${({ notificationBar }) =>
    notificationBar ? "11.7rem" : "6.9rem"};
  }
`;

export const Title = styled(Heading)`
  color: ${({ theme }) => theme.colors.font_black};
`;

export const Subtitle = styled(SectionHeading)``;

export const StyledButton = styled(Button)`
  margin-top: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 5rem;
  }
`;
