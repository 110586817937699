import React, { useEffect } from "react";
import { RenderModules } from "src/utils/renderModules";
import SEO from "src/components/SEO";
import { Container } from "./styles";
import { useCart, useDiscount } from "src/context/siteContext";
import applyDiscount from "src/utils/applyDiscount";
import RedirectModal from "src/components/RedirectModal";

export interface CollectionProps {
  pageContext: {
    modules: [];
    slug: string;
    main: {
      title: string;
    };
    meta: {};
    siteGlobal: [];
    selectCountry: {
      title: string;
      flag: {
        asset: string;
        alt: string
      },
      link: string;
    }
  };
  path?: string;
  preview?: boolean;
}

const Collection = ({
  path,
  pageContext,
  preview = false,
}: CollectionProps) => {
  const { modules, slug, meta, siteGlobal, selectCountry } = pageContext;

  const { addDiscount } = useDiscount();
  const { checkout } = useCart();
  const id = checkout?.id;

  useEffect(() => {
    applyDiscount(id, addDiscount, checkout?.lineItems?.length);
  }, [id]);

  const url = `/collections/${pageContext?.slug}`;
  return (
    <Container
      notificationBar={
        pageContext.notificationBar
          ? pageContext.notificationBar[0].enabled
          : false
      }
    >
      <SEO
        metaInfo={meta}
        pagePath={url}
        globalMeta={siteGlobal?.metaInformation}
      />
      <RedirectModal data={selectCountry} />
      <div>{RenderModules(modules, pageContext)}</div>
    </Container>
  );
};

export default Collection;
